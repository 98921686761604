*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --html-font-size: calc(14.25px + 0.390625vw);
}

html {
    font-size: var(--html-font-size);
}

@media only screen and (min-width: 101em) {
  :root {
    --html-font-size: calc(16.5px + 0.4vw)
  }
}

@media only screen and (min-width: 125em) {
  :root {
    --html-font-size: calc(16.5px + 0.59vw)
  }
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


